@import '@UIkit/styles/typography/text';
@import '@UIkit/styles/global/Colors/Variables.scss';

.invitations {
	&-logo {
		&-title {
			display: block;
			@include typography__body-short_01();
			position: relative;
			left: 152%;
			width: max-content;
		}
		&-column-item {
			&-incoming {
				padding: 0 0 0 25px;
			}
		}
	}
	&-receiver {
		height: 24px;

		&-panel {
			background: $color-background;
			border-radius: 4px;

			.x-panel-body {
				background-color: $color-background;
			}
		}
	}

	&-products {
		display: flex;
		justify-items: start;
		align-items: center;
		column-gap: 16px;

		&-logo {
			width: 24px;
			height: 24px;
			background-repeat: no-repeat;
			background-position: left center;
			background-size: auto 20px;

			&-ediweb {
				width: 80px;
				height: 16px;
				background-image: url(@Themes/ediweb/images/projectLogo/ediweb.svg);
				background-size: auto 16px;
			}
			&-coreplat {
				background-image: url(@Themes/ediweb/images/projectLogo/coreplat.svg);
			}
			&-factorplat {
				background-image: url(@Themes/ediweb/images/projectLogo/factorplat.svg);
			}
			&-dropcat {
				background-image: url(@Themes/ediweb/images/projectLogo/dropcat.svg);
			}
			&-linkserver {
				background-image: url(@Themes/ediweb/images/projectLogo/linkserver.svg);
			}
			&-edi_agent {
				background-image: url(@Themes/ediweb/images/projectLogo/edi_agent.svg);
			}
			&-edi_courier {
				background-image: url(@Themes/ediweb/images/projectLogo/edi_courier.svg);
			}
			&-one_c_module {
				background-image: url(@Themes/ediweb/images/projectLogo/1c_modul.svg);
			}
		}

		&-name {
			@include typography__body-short_01();
			color: $color-dark;
		}
	}

	&-notification-block {
		background: #f3f4f4;
		padding: 16px 4px 16px 40px;
		word-wrap: break-word;

		&-link {
			color: #3d7dca;
			padding-top: 16px;
		}
	}
}
