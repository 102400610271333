@import '@UIkit/styles/typography/text';
@import '@UIkit/styles/global/Colors/Variables.scss';

.invitations-mass {
	&-attachment {
		&-icon {
			font-size: 18px;
			color: $color-grey-50;
		}
		&-text {
			padding: 0 0 16px 0;
		}
	}

	&-workinediweb {
		font-size: 24px;
		color: $color-grey-50;
	}

	&-error-report {
		&-init {
			background-color: $color-grey-30;
		}
		&-empty {
			background-color: $color-teal;
		}
		&-with-error {
			background-color: $color-error;
		}

		&-label {
			@include typography__caption_01();

			color: $color-grey-50;
			display: inline-block;
			height: 20px;
		}
		&-count {
			@include typography__caption_01();

			color: $color-white;

			text-align: center;
			display: inline-block;
			width: 20px;
			height: 20px;
			line-height: 20px;
			border-radius: 50%;
		}
	}

	&-grid {
		&-error-sending {
			.x-grid-cell,
			.x-grid-cell-first {
				background-color: $color-error-opacity-5 !important;
			}

			.x-grid-cell-first {
				border-left: 4px solid $color-error !important;
				padding-left: 8px !important;
			}
		}

		& .x-grid-empty {
			min-height: 130px;
			background: url('../assets/userIcon.png') center center no-repeat !important;
		}
	}
}
