@import '@UIkit/styles/typography/text';
@import '@UIkit/styles/global/Colors/Variables.scss';

.invitation {
	&-orgselector {
		&-buttons {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
		}
	}
	&-receiver {
		display: block;
		position: relative;
		padding-bottom: 24px;

		&-title {
			display: block;
			padding-left: 34px;

			&::before {
				background-image: url('../assets/blue_info.svg');
				display: block;
				content: '';
				width: 18px;
				height: 18px;
				position: absolute;
				top: 0;
				left: 0;
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
		&-btn-close {
			position: absolute;
			top: 0;
			right: 0;
		}
	}
	&-alert {
		border-radius: 3px;
		padding: 16px;
		margin: 8px 0 24px 0 !important;

		background-color: $color-error-opacity-10;
	}
}

.ui-plugin-combo-panel-inside-list {
	&-child {
		&.text {
			&-send {
				display: block;
				cursor: pointer;
				text-decoration: underline;

				.ui-label-text {
					@extend .body-long_01;
					color: $color-blue !important;
				}

			}
			&-notfound {
				display: block;
				padding-bottom: 8px;

				.ui-label-text {
					@extend .caption_01;
					color: $color-grey-50 !important;
				}
			}
		}
	}
}
