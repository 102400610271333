.edi-viewport {
	.edi-state-container-POWER_OF_ATTORNEY_REVOKED,
	.edi-state-container-POWER_OF_ATTORNEY_EXPIRED {
		border-color: #1d252c;

		.status-POWER_OF_ATTORNEY_REVOKED,
		.status-POWER_OF_ATTORNEY_EXPIRED {
			color: #1d252c;
		}
	}

	.edi-state-container-POWER_OF_ATTORNEY_STATUS_errors {
		border-color: #e82000;

		.status-REJECT,
		.status-REVOKED,
		.status-EXPIRED {
			color: #e82000;
		}
	}

	.edi-state-container-POWER_OF_ATTORNEY_STATUS_withoutErrors {
		border-color: #00ac2d;

		.status-COMPLETED,
		.status-APPROVED {
			color: #00ac2d;
		}
	}
}
