@use '@UIkit/styles/typography/_text.scss';
@import '@UIkit/styles/global/Colors/Variables.scss';

.partner-combo {
	.partner-name {
		@extend .body-short_01;
		margin-bottom: 4px;
	}

	.partner-row {
		display: flex;
		gap: 8px;
		align-items: center;
		font-size: inherit;
		line-height: 16px;

		&-item {
			.title,
			.text {
				@extend .caption_01;
				color: $color-grey-50;
			}

			.title {
				margin-right: 4px;
			}
		}
	}
}
