.ui-product-tree-grid {
	.child-node {
	}

	.is-edit-editable-tree-grid {
	}

	.with-error:not(.read-only-editable-tree-grid),
	.without-error:not(.read-only-editable-tree-grid),
	.not-valid-record:not(.read-only-editable-tree-grid) {
		.editable .x-grid-cell-inner {
			background-color: #f8f8f8;
		}
	}

	.with-error .x-grid-cell {
		background-color: rgba(232, 32, 0, 0.1) !important;
	}

	.x-grid-tree-node-expanded {
		.x-grid-cell {
			background-color: #e2ecf7 !important;

			.x-action-col-icon {
				background: #d5e6ed !important;
			}
		}
	}

	.not-valid-record .x-grid-cell {
		background-color: #f3f4f4 !important;
	}
}

.ui-product-tree-grid-container {
	.edi-total-grid {
		.x-grid-item:last-child {
			border-bottom: none;
		}
	}
}
