@import '@UIkit/styles/typography/text';
@import '@UIkit/styles/global/Colors/Variables.scss';

.ui-plugin-combo-panel-inside-list {
	&-wrap {
		padding: 16px;
	}

	.ui-boundlist {
		&-list {
			&-plain {
				&:has(li) {
					padding: 0 0 16px 0;
				}
			}
		}
	}
}