@import '@UIkit/styles/typography/text';
@import '@UIkit/styles/global/Colors/Variables.scss';

.ediweb-orgautocomplete {
	&-text {
		display: block;
		margin: 2px 0 0 0;

		@extend .caption_02;
		color: $color-grey-50;
	}
}
