.ediweb-combologo {
	&-field,
	&-list-item {
		&::before {
			content: '';
			display: block;
			width: 28px;
			height: 28px;

			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
			margin-left: 4px;
		}
	}

	&-list-item {
		display: flex;
		justify-content: start;
		align-items: center;

		&::before {
			content: '';
			position: initial;
			margin-right: 8px;
			transform: none;
		}
	}
}

.ediweb-combologo-list {
	&-ediweb {
		&::before {
			width: 72px;
			background-image: url('@Themes/ediweb/images/projectLogo/ediweb.svg');
		}
		.ui-boundlist-item-inner {
			display: none;
		}
	}
	&-dropcat {
		&::before {
			background-image: url('@Themes/ediweb/images/projectLogo/dropcat.svg');
		}
	}
	&-coreplat {
		&::before {
			background-image: url('@Themes/ediweb/images/projectLogo/coreplat.svg');
		}
	}
	&-factorplat {
		&::before {
			background-image: url('@Themes/ediweb/images/projectLogo/factorplat.svg');
		}
	}
	&-linkserver {
		&::before {
			background-image: url('@Themes/ediweb/images/projectLogo/linkserver.svg');
		}
	}
	&-edi_agent {
		&::before {
			background-image: url('@Themes/ediweb/images/projectLogo/edi_agent.svg') !important;
		}
	}
	&-edi_courier {
		&::before {
			background-image: url('@Themes/ediweb/images/projectLogo/edi_courier.svg') !important;
		}
	}
	&-one_c_module {
		&::before {
			background-image: url('@Themes/ediweb/images/projectLogo/1c_modul.svg') !important;
		}
	}
}
