@import '@UIkit/styles/global/Colors/Variables';

.edi-document-header-panel {
	background-color: $color-blue-opacity-5;
	border-radius: 8px;
	padding: 24px 24px 0 24px;

	&-countWithIcon {
		margin: 0 0 0 8px;
	}

	.x-panel-body {
		background: transparent;
	}

	//у филдблока по умолчанию 8пкс (т.к. еще 8 берутся из поля), а тут не поля и дизайнеры хотят 16пкс
	.ui-fieldblock-title {
		margin-bottom: 13px;
	}

	&-warningAndErrorDocs {
		&.icon {
			&-error {
				.ui-button-icon-el {
					color: $color-error;
				}
			}
			&-warning {
				.ui-button-icon-el {
					color: $color-yellow;
				}
			}
		}
	}
}
