@import '@UIkit/styles/global/Colors/Variables.scss';
.desadv {
	&-tree-actions-toolbar {
		border-top: 1px solid $color-grey-20 !important;
	}

	&-tree-tabpanel {
		margin: 8px 0 !important;
	}
}
