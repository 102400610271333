@use '@UIkit/styles/typography/text';
@use '@UIkit/styles/typography/heading';
@import '@UIkit/styles/global/Colors/Variables';

//карточки Полномочий на форме просмотра
.edi-poa-power-list-card-container {
	padding: 24px;
	border-radius: 8px;
	background-color: $color-blue-opacity-5;

	.edi-poa-limitations-list {
		padding-left: 16px;
		li {
			padding-bottom: 16px;
		}
		li:last-of-type {
			padding-bottom: 0px;
		}
		div {
			padding-left: 8px;
		}

		.detail-label {
			color: $color-grey-50;
			margin-top: 8px;
		}
	}
}

//карточки Полномочий на форме создания/редактирования
.edi-poa-power-block.ui-blocksholder {
	padding: 0;
	background-color: transparent;

	.edi-poa-power-card {
		display: flex;
		flex-direction: column;

		&-title {
			@extend .body-short_02;
		}

		&-value {
			margin-top: 8px;
			@extend .body-short_01;
		}

		&-limitations {
			display: flex;
			flex-direction: column;

			&-title {
				margin-top: 24px;
				@extend .body-short_02;
			}

			&-list {
				margin: 0;
				padding: 0;

				li {
					position: relative;
					margin-top: 16px;
					left: 28px;
					list-style: none;
					counter-increment: list0 1;

					&::before {
						position: absolute;
						left: -28px;
						display: block;
						height: 100%;
						content: counter(list0, numeric) '.\00A0';
						@extend .body-short_02;
					}
				}
			}

			&-value {
				@extend .body-short_01;
			}

			&-subtitle {
				margin-top: 8px;
				@extend .caption_01;
				color: $color-grey-50;
			}

			&-description {
				@extend .body-short_01;
			}
		}
	}
}

.edi-poa-power-block-ogr-row {
	.edi-poa-power-block-ogr-index {
		width: 24px !important;
		//margin-top: 16px !important;
		//margin-right: 4px !important;
	}
}

.poa-collective-powers-warn {
	padding: 24px;
	color: $color-error;
	@extend .caption_01;
}

.ui-table-empty-text-with-image-icon-powers-attorney {
	background-image: url(images/no_powers_of_attorney.png);
}

.ui-editableblock {
	.title-type {
		margin-bottom: 16px;
		@extend .heading_01;
	}

	.sub-title-type {
		margin-top: 24px;
		margin-bottom: 16px;
		@extend .heading_01;
	}

	.edi-company-name,
	.edi-company-row {
		margin: 4px 0;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.edi-company-name {
		@extend .body-short_02;
	}

	.edi-company-row {
		display: block;
		white-space: normal;
		font-size: inherit;
		line-height: 16px;

		.code {
			display: inline-block;
			@extend .caption_01;
			color: $color-grey-50;
			margin-right: 4px;
		}

		.row-data,
		.inline-row-data {
			display: inline-block;
			@extend .body-short_01;
			color: $color-dark;
			margin-right: 16px;
		}

		.inline-row-data {
			margin-right: 0;
		}

		.block {
			display: block;
			margin-top: 24px;
			margin-bottom: 16px;
		}
	}
}

.edi-poa-retrust-number {
	cursor: pointer;
}

.x-menu-item-default.x-menu-item-separator.power-of-attorney-import-from-fns-menu-separator {
	height: 1px;
	padding: 0;
	background: $color-grey-20;
}
