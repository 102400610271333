@import '@UIkit/styles/global/Colors/Variables.scss';

.packing-list {
	.ui-table .x-grid-body .x-tree-view .x-grid-item .x-grid-row {
		background: $color-blue-5;
		&.x-grid-tree-node-expanded {
			background: $color-blue-15;
			.x-grid-cell-inner-portable-row {
				.x-tree-node-text {
					color: $color-blue;
				}
			}
		}
	}
}
