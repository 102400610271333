@use '@UIkit/styles/typography/text';
@use '@UIkit/styles/typography/_heading.scss';
@import '@UIkit/styles/global/Colors/Variables.scss';

.edi-viewport {
	.row-with-nested-rows {
		border-radius: 8px;

		.row-with-nested-row {
			.external-row {
				background: rgba(61, 125, 202, 0.05);
				padding: 24px;
				border-radius: 8px;

				.external-title {
					@extend .heading_02;
					color: #1d252c;
				}

				.edi-state-container {
					color: #3d7dca;
					padding: 2px 7px;
					@extend .body-short_01;
				}

				.x-box-inner {
					min-height: 26px !important;
				}

				.row-buttons > span > div {
					float: right;
				}

				.internal-rows-panel {
					background-color: rgba(61, 125, 202, 0.05);

					.internal-row-panel {
						.x-panel-body-default {
							background-color: transparent;
							.ui-simple-selector-details-container-body {
								background-color: $color-dark-opacity-5;
							}
						}

						.row-buttons {
							padding: 16px 24px 0 0;
						}

						.internal-row {
							background-color: #ebf1f9;
							border-radius: 8px;

							.row-actions {
								height: 56px;

								.internal-title {
									@extend .heading_01;
									padding: 16px 24px;
									color: #1d252c;
								}
							}
						}

						.internal-row-warning {
							.row-actions {
								background-color: rgba(232, 32, 0, 0.05);
							}

							background-color: #f3ecee;
						}
					}
				}
			}
		}
	}
}

.ui-fieldset {
	.row-with-nested-rows {
		padding: 0 !important;

		.internal-row-panel {
			.x-panel:first-child > .x-panel-bodyWrap > .x-panel-body {
				padding: 0 0 0 24px;
			}
		}
	}
}
