@import '@UIkit/styles/global/Colors/Variables';

.documents {
	&-grid {
		&-warningAndErrorDocs {
			&.icon {
				&-error {
					color: $color-error !important;
				}
				&-warning {
					color: $color-yellow !important;
				}
			}
		}
	}
}

ul.exception-type-warning,
ul.exception-type-error {
	.ui-label {
		display: list-item;
		list-style-position: inside;
	}
}
