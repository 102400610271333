@use '@UIkit/styles/typography/heading';
@import '@UIkit/styles/global/Colors/Variables';

.edi-viewport .x-panel-default.edi-product-grid-container {
	//что бы тени грида было видно, если они заданы
	overflow: visible;

	.x-panel-header {
		background-color: transparent;
		border: none;
		padding: 0 0 8px 0;

		.x-header-text,
		.x-title-text {
			@extend .heading_01;
		}
	}

	> .x-panel-bodyWrap {
		//что бы тени грида было видно, если они заданы
		overflow: visible;

		> .x-panel-body {
			//что бы тени грида было видно, если они заданы
			overflow: visible;
		}
	}

	.edi-grid-products {
	}

	.edi-total-grid {
		.x-grid-item:last-child {
			border-bottom: none;
		}
	}
}
