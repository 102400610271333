.ui-label-with-icon {
	display: flex;
	align-items: center;
	gap: 16px;

	&-icon {
		font-size: 24px;
		line-height: 24px;
	}
}
